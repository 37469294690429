import React from "react";
import cx from "classnames";

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {}

/**
 * Card component with a shadow effect.
 * @param {Object} props - Component props.
 * @param {React.ReactNode} props.children - The content to be displayed inside the card.
 * @returns {React.ReactElement} - The Card component.
 */
const Card: React.FC<CardProps> = ({ children, className, ...props }) => {
  // Define Tailwind CSS classes for the card
  const cardClasses = cx(
    "bg-white",
    "p-6",
    "rounded-md",
    "shadow-md shadow-blue-200/20 hover:shadow-lg hover:shadow-blue-300/20",
    "rounded-md transition-all duration-500 ease-in-out ",
  );

  return (
    <div className={cx(cardClasses, className)} {...props}>
      {children}
    </div>
  );
};

export default Card;
