import React from "react";
import { cn } from "../lib/utils";

interface WrapProps extends React.HTMLAttributes<HTMLDivElement> {}

const Wrap: React.FC<WrapProps> = ({ className, children, ...props }) => {
  return (
    <div
      className={cn(
        className,
        "flex justify-center items-center w-full"
      )}
      {...props}
    >
      <div className={"w-full max-w-screen-2xl m-4"}>{children}</div>
    </div>
  );
};

export default Wrap;
