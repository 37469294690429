import React from "react";
import cx from "classnames";
import { Link, LinkProps } from "react-router-dom";

interface LinkButtonProps extends LinkProps {
  icon?: React.ReactNode;
}

/**
 * LinkButton component with optional icon and routing functionality.
 * @param {Object} props - Component props.
 * @param {React.ReactNode} [props.icon] - An optional icon to display before the link's content.
 * @param {string} props.to - The target URL to navigate to.
 * @param {React.ReactNode} props.children - The content of the link.
 * @returns {React.ReactElement} - The LinkButton component.
 */
const LinkButton: React.FC<LinkButtonProps> = ({ icon, children, className, ...props }) => {
  // Check if an icon is provided
  const hasIcon = !!icon;

  // Define Tailwind CSS classes based on link properties
  const linkClasses = cx(
    "py-2 px-4 border border-primary",
    "rounded-md transition-all duration-300 ease-in-out hover:opacity-70 active:scale-95",
    "shadow-md shadow-blue-200/20 hover:shadow-lg hover:shadow-blue-300/20",
    {
      "flex items-center gap-x-2": hasIcon, // Apply flex layout if an icon is provided
    }
  );

  return (
    <Link className={cx(linkClasses, className)} {...props}>
      {icon && <span className="w-5 h-5">{icon}</span>}
      {children}
    </Link>
  );
};

export default LinkButton;
