import axios from "axios";
import { LocalesList } from "../types/Locales";

export const getLocalesList = async (owner: string, repositoryName: string) => {
  try {
    const response = await axios.get(
      `/locale/locales/${owner}/${repositoryName}`,
      { withCredentials: true }
    );
    if (response.data) {
      return response.data as LocalesList[];
    }
    return [];
  } catch (error) {
    console.log("Get repository locales error:", error);
    return undefined;
  }
};

export const saveLocalesList = async (
  owner: string,
  repositoryName: string,
  data: LocalesList[]
) => {
  try {
    const response = await axios.post(
      `/locale/update/${owner}/${repositoryName}`,
      { locales: data },
      { withCredentials: true }
    );

    return response.data.message;
  } catch (error) {
    console.log("Save repository locales error:", error);
    return error;
  }
};

// export default getLocalesList;
