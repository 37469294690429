import React from "react";
import { Route, Routes } from "react-router-dom";

import HomePage from "./pages/Home";
import LoginPage from "./pages/Login";
import PrivateRoute from "./helpers/PrivateRoute";
import EditPage from "./pages/Edit";

const App: React.FC = () => {
  return (
    <div className="App">
      <Routes>
        {/* Public routes */}
        <Route path="/login" element={<LoginPage />} />

        {/* Pivate routes */}
        <Route path="/" element={<PrivateRoute element={<HomePage />} />} />
        <Route
          path="/edit/:owner/:repo"
          element={<PrivateRoute element={<EditPage />} />}
        />
      </Routes>
    </div>
  );
};

export default App;
