import React, { useEffect } from "react";

import useAuthStore from "../services/AuthService";
import Header from "../components/layouts/Header";
import useRepoStore from "../services/RepoService";
import Card from "../components/ui/Card";
import { Button } from "../components/shadcn/button";
import { cn } from "../lib/utils";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/shadcn/table";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../components/shadcn/tooltip";
import Wrap from "../components/Wrap";
import { Avatar, AvatarImage } from "../components/shadcn/avatar";
import { Github, Pencil } from "lucide-react";
import { Link } from "react-router-dom";
import ScreenSpinner from "../components/ui/ScreenSpinner";

const HomePage: React.FC = () => {
  const { user } = useAuthStore();
  const { repo, getRepositories } = useRepoStore();

  useEffect(() => {
    if (!repo) {
      const getRepoData = async () => {
        await getRepositories();
      };

      getRepoData();
    }
  }, [getRepositories, repo]);
  return (
    <>
      {user && <Header user={user} />}

      <Wrap>
        <Card className={cn("w-full")}>
          <Table>
            <TableCaption>
              List of available repositories with the ability to localize
            </TableCaption>
            <TableHeader>
              <TableRow>
                <TableHead className="w-[100px]">Owner</TableHead>
                <TableHead>Repository</TableHead>
                <TableHead>Description</TableHead>
                <TableHead className="text-right">action</TableHead>
              </TableRow>
            </TableHeader>

            <TableBody>
              {repo?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {item.avatar ? (
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Link to={item.ownerUrl} target="_blank">
                              <Avatar className="hover:animate-pulse">
                                <AvatarImage src={item.avatar} />
                              </Avatar>
                            </Link>
                          </TooltipTrigger>
                          <TooltipContent>{item.owner}</TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    ) : (
                      <span>{item.owner}</span>
                    )}
                  </TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>
                    {item.description ? (
                      item.description
                    ) : (
                      <span className={cn("text-gray-500")}>
                        No description...
                      </span>
                    )}
                  </TableCell>
                  <TableCell className="text-right">
                    <div className={"flex gap-4 justify-end"}>
                      <Button asChild variant={"outline"}>
                        <Link to={`edit/${item.owner}/${item.name}`}>
                          <Pencil className="mr-2 h-4 w-4" /> Edit
                        </Link>
                      </Button>
                      <Button asChild variant={"outline"}>
                        <Link to={item.repoUrl} target="_blank">
                          <Github className="mr-2 h-4 w-4" /> GitHub
                        </Link>
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {!repo && <ScreenSpinner />}
        </Card>
      </Wrap>
    </>
  );
};

export default HomePage;
