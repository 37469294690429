import React, { useState, useEffect } from "react";
import cx from "classnames";

import baseURL from "../config/config";
import SvgGithub from "./icons/SvgGithub";
import LinkButton from "./ui/link/LinkButton";
import Card from "./ui/Card";
import SvgInfo from "./icons/SvgInfo";
import InfoCard from "./ui/InfoCard";
import SvgGit from "./icons/SvgGit";
import SvgArrowLeftCircleFill from "./icons/SvgArrowLeftCircleFill";

const GitHubAuth: React.FC = () => {
  const [showCard, setShowCard] = useState(false);

  useEffect(() => {
    // Add a short delay to allow for smooth rendering
    const timeout = setTimeout(() => {
      setShowCard(true);
    }, 400);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="p-4 md:p-8 lg:p-12">
      <Card
        className={cx("flex flex-col gap-y-4 w-full max-w-[750px] m-auto", {
          "opacity-0 transition-opacity duration-1000 ease-in-out": !showCard,
          "opacity-100": showCard,
        })}
      >
        <h2 className="text-3xl text-center">Authorization</h2>
        <InfoCard className={cx("!flex-row")}>
          <span className="w-6 h-6 rounded-full border">
            <SvgInfo />
          </span>
          <p className="text-sm">
            To work with this application, you need to log in using your GitHub
            account.
          </p>
        </InfoCard>

        <div className="flex justify-center">
          <LinkButton to={`${baseURL}/auth/github`} icon={<SvgGithub />}>
            Authorize with GitHub
          </LinkButton>
        </div>

        <hr className="w-full border-gray-100 my-4 h-px" />

        <div className="flex flex-col gap-4">
          <h3 className="text-xl text-gray-500">What will happen next?</h3>

          <div className="flex flex-col md:flex-row gap-4 text-sm md:text-center">
            <InfoCard>
              <span className="w-8 h-8">
                <SvgGithub />
              </span>
              Next, you will be taken to the GitHub application.
            </InfoCard>
            <InfoCard>
              <span className="w-8 h-8">
                <SvgGit />
              </span>
              You will need to check the boxes for those companies that you want
              to provide access to.
            </InfoCard>
            <InfoCard>
              <span className="w-8 h-8">
                <SvgArrowLeftCircleFill />
              </span>
              GitHub will then redirect you back to this application.
            </InfoCard>
          </div>

          <hr className="w-full border-gray-100 my-4 h-px" />

          <p className="text-sm text-gray-400">
            At the end of this simple path, you will have access to all
            repositories that contain localization files. You can easily select
            the project you are interested in, change the required line of text,
            and immediately save and push these changes to GitHub.
          </p>
        </div>
      </Card>
    </div>
  );
};

export default GitHubAuth;
