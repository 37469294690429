import React, { useEffect, useState } from "react";
import Wrap from "../components/Wrap";
import Card from "../components/ui/Card";
import { Link, useParams } from "react-router-dom";
import useAuthStore from "../services/AuthService";
import Header from "../components/layouts/Header";
import { LocalesList } from "../types/Locales";
import JsonEditorForm from "../components/JsonEditorForm";
import Spinner from "../components/ui/Spinner";
import { getLocalesList, saveLocalesList } from "../services/LocalizeService";
import { useToast } from "../components/shadcn/toast/use-toast";
import { Toaster } from "../components/shadcn/toast/toaster";
import { ToastAction } from "../components/shadcn/toast/toast";

const EditPage: React.FC = () => {
  const { user } = useAuthStore();
  const { owner, repo } = useParams();
  const [locales, setLocales] = useState<LocalesList[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      if (owner && repo) {
        const data = await getLocalesList(owner, repo);
        setIsLoading(false);
        if (!data) return setIsError(true);
        setIsError(false);
        setLocales(data);
      }
    };

    getData();
  }, [owner, repo]);

  const handleUpdateJson = async (updatedJson: LocalesList[]) => {
    setLocales(updatedJson);

    if (owner && repo) {
      setIsLoading(true);

      const response = await saveLocalesList(owner, repo, updatedJson);
      setIsLoading(false);
      if (response) {
        toast({
          description: "Pull request successfully created.",
          action: (
            <ToastAction asChild altText="Open github pull request page">
              <Link to={response} target="_blank">
                Open
              </Link>
            </ToastAction>
          ),
        });
      } else {
        toast({
          description: "An error occurred while creating the pull request.",
        });
      }
    }
  };

  return (
    <>
      <Header user={user} repositoryName={repo} owner={owner} />
      <Wrap>
        <Card>
          <div>
            {locales && locales.length > 0 && !isLoading && (
              <div>
                <JsonEditorForm
                  localesList={locales}
                  onUpdate={handleUpdateJson}
                />
              </div>
            )}
          </div>
          <div className={"flex flex-col"}>
            {locales.length == 0 && !isLoading && (
              <div className={"flex w-full justify-center"}>
                <span>There are no localization files yet</span>
              </div>
            )}
            {isLoading && (
              <div
                className={
                  "flex flex-col items-center gap-4 w-full justify-center"
                }
              >
                <Spinner className="w-8 h-8" />
                <span className={"text-gray-400 uppercase"}>Loading...</span>
              </div>
            )}
            {isError && <span>Error</span>}
          </div>
          <Toaster />
        </Card>
      </Wrap>
    </>
  );
};

export default EditPage;
