import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../services/AuthService";

/**
 * A private route component that checks if the user is authenticated.
 * If the user is authenticated, it renders the provided element; otherwise, it redirects to the login page.
 * @param {Object} props - Component props.
 * @param {React.ReactNode} props.element - The element to render if the user is authenticated.
 * @returns {React.ReactNode} - The private route component.
 */
const PrivateRoute: React.FC<{ element: React.ReactNode }> = ({ element }) => {
  const { user, checkAuthentication } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      const redirectToLogin = async () => {
        const userData = await checkAuthentication();
        if (!userData) return navigate("/login");
      };

      redirectToLogin();
    }
  }, [checkAuthentication, navigate, user]);

  return <>{user ? element : null}</>;
};

export default PrivateRoute;
