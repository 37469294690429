import React from "react";
import cx from "classnames";

interface InfoCardProps extends React.HTMLAttributes<HTMLDivElement> {}

/**
 * Card component with a shadow effect.
 * @param {Object} props - Component props.
 * @param {React.ReactNode} props.children - The content to be displayed inside the card.
 * @returns {React.ReactElement} - The Card component.
 */
const InfoCard: React.FC<InfoCardProps> = ({ children, className, ...props }) => {
  // Define Tailwind CSS classes for the card
  const cardClasses = cx(
    "border border-[#F6F8FC] shadow-inner shadow-blue-200/20 rounded-md p-4 text-gray-400 flex md:flex-col items-center gap-4",
  );

  return (
    <div className={cx(className, cardClasses)} {...props}>
      {children}
    </div>
  );
};

export default InfoCard;
