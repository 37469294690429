import React from "react";
import { cn } from "../lib/utils";
import { flag } from "country-emoji";

interface LangBlockProps extends React.HTMLAttributes<HTMLDivElement> {
  lang: string;
}
const LangBlock: React.FC<LangBlockProps> = ({ lang, className, ...props }) => {
  const languageNames = new Intl.DisplayNames(["en"], { type: "language" });
  const langName = lang.split(".")[0].toUpperCase();

  return (
    <div className={cn(className, "flex items-center gap-2")} {...props}>
      <span
        className={
          "flex items-center justify-center rounded-full border w-11 h-11 text-xl"
        }
      >
        {flag(langName === "EN" ? "UK" : langName)}
      </span>
      <span>
        <p>{languageNames.of(langName)}</p>
        <p className={"text-gray-400"}>{langName}</p>
      </span>
    </div>
  );
};

export default LangBlock;
