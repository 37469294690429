import { cn } from "src/lib/utils";

import React from "react";

interface SpinnerProps extends React.HTMLAttributes<HTMLDivElement> {}

const ScreenSpinner: React.FC<SpinnerProps> = ({ className, ...props }) => {
  return (
    <div
      role="status"
      className={cn(
        className,
        "flex justify-center items-center absolute right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2 backdrop-blur-sm bg-white/30 w-full h-screen"
      )}
      {...props}
    >
      <div className="border-t-transparent border-solid animate-spin  rounded-full border-blue-500/60 border-8 h-64 w-64"></div>
    </div>
  );
};

export default ScreenSpinner;
