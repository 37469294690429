import React from "react";
import { ChevronLeft, LogOut } from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/shadcn/popover";
import Card from "../ui/Card";
import { cn } from "../../lib/utils";
import { User } from "../../types/user";
import { Avatar, AvatarImage } from "../shadcn/avatar";
import Separator from "../ui/Separator";
import { Button } from "../shadcn/button";
import { useLocation, useNavigate } from "react-router-dom";
import useAuthStore from "../../services/AuthService";
import useRepoStore from "../../services/RepoService";

/**
 * Header component.
 */
interface HeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  repositoryName?: string;
  user?: User;
  owner?: string;
}

const Header: React.FC<HeaderProps> = ({
  user,
  className,
  children,
  repositoryName,
  owner,
  ...props
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const headerClasses = "mb-4 border-b";
  const { logout } = useAuthStore();
  const { clearStore } = useRepoStore();

  const logoutHandler = async () => {
    await logout();
    clearStore();
  };

  return (
    <header className={cn(className, headerClasses)} {...props}>
      <Card className={cn("flex flex-col items-center")}>
        <div
          className={cn(
            "flex justify-between items-center w-full max-w-screen-2xl"
          )}
        >
          <nav>
            {repositoryName && owner ? (
              <nav className={"flex gap-4 items-center"}>
                {location.pathname !== "/" && (
                  <Button
                    aria-label="Return to previous page"
                    onClick={() => navigate(-1)}
                    variant="outline"
                    size="icon"
                  >
                    <ChevronLeft className="h-4 w-4" />
                  </Button>
                )}
                <RepositoryPathCard>
                  {collectRepositoryPath(owner, repositoryName)}
                </RepositoryPathCard>
              </nav>
            ) : (
              <RepositoryPathCard>
                You haven`t selected a repository yet
              </RepositoryPathCard>
            )}
          </nav>
          <nav>{children}</nav>
          <nav>
            {user ? (
              <Popover>
                <PopoverTrigger>
                  <div className={cn("flex gap-2 items-center")}>
                    <span>{user.displayName}</span>
                    <Avatar>
                      <AvatarImage src={user.photo} />
                    </Avatar>
                  </div>
                </PopoverTrigger>
                <PopoverContent>
                  {user.username && <p>Username: {user.username}</p>}
                  {user.name && <p>Name: {user.name}</p>}
                  {user.githubId && <p>Git number: {user.githubId}</p>}
                  <Separator />

                  <Button onClick={() => logoutHandler()} variant={"outline"}>
                    <LogOut className={cn("mr-2 h-4 w-4")} />
                    Logout
                  </Button>
                </PopoverContent>
              </Popover>
            ) : null}
          </nav>
        </div>
      </Card>
    </header>
  );
};

/**
 * Components
 */

interface RepositoryPathCardProps
  extends React.HTMLAttributes<HTMLDivElement> {}

/**
 * RepositoryPathCard component.
 */
const RepositoryPathCard: React.FC<RepositoryPathCardProps> = ({
  className,
  children,
  ...props
}) => {
  const repositoryCardClass =
    "rounded font-normal text-gray-500 bg-blue-100/30 border border-gray-200 p-2";
  return (
    <div className={cn(className, repositoryCardClass)} {...props}>
      {children}
    </div>
  );
};

/**
 * Helpers
 */

/**
 * Helper function to collect repository path.
 */
const collectRepositoryPath = (
  owner: string,
  repositoryName: string
): string => {
  return `/${owner}/${repositoryName}`;
};

export default Header;
