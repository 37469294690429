import * as React from "react";
import { cn } from "../../lib/utils";

export interface DyTextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const DyTextarea = React.forwardRef<HTMLTextAreaElement, DyTextareaProps>(
  ({ className, ...props }, ref) => {
    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === "Enter") {
        e.preventDefault();
      }
    };

    return (
      <textarea
        className={cn(
          "flex w-full resize-y rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed",
          className
        )}
        ref={ref}
        onKeyDown={handleKeyDown}
        {...props}
      />
    );
  }
);

DyTextarea.displayName = "Textarea";

export { DyTextarea };
