import React, { useMemo, useState } from "react";
import { DyTextarea } from "./shadcn/dynamic-textarea";
import { LocalesList } from "../types/Locales";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/shadcn/table";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../components/shadcn/tooltip";
import { Button } from "./shadcn/button";
import LangBlock from "./LangBlock";
import { Input } from "./shadcn/input";

/**
 * Props for the JsonEditorForm component.
 */
interface JsonEditorFormProps {
  localesList: LocalesList[];
  onUpdate: (updatedJson: LocalesList[]) => void;
}

/**
 * JsonEditorForm is a React component for editing JSON data.
 */
const JsonEditorForm: React.FC<JsonEditorFormProps> = ({
  localesList,
  onUpdate,
}) => {
  const [editedJson, setEditedJson] = useState<LocalesList[]>(localesList);
  const [searchQuery, setSearchQuery] = useState("");

  /**
   * Handle input change for a specific key.
   * @param key - The key to update.
   * @param value - The new value for the key.
   * @param locale - The target locale.
   */
  const handleInputChange = (key: string, value: any, locale: LocalesList) => {
    const updatedLocales = editedJson.map((item) => {
      if (item === locale) {
        const updatedContent = { ...item.content };
        const keys = key.split(".");
        let currentObj: Record<string, any> = updatedContent;

        for (let i = 0; i < keys.length; i++) {
          const k = keys[i];
          if (i === keys.length - 1) {
            currentObj[k] = value;
          } else {
            currentObj[k] = currentObj[k] || {};
            currentObj = currentObj[k];
          }
        }

        return {
          ...item,
          content: updatedContent,
        };
      }
      return item;
    });

    setEditedJson(updatedLocales);
  };

  /**
   * Render input fields based on JSON data.
   * @param data - The JSON data to render.
   * @param parentKey - The parent key (used for nesting).
   */
  const renderInputs = (data: Record<string, any>, parentKey = "") => {
    const inputRows: JSX.Element[] = [];

    const getValueFromKey = (
      key: string,
      data: Record<string, any>
    ): string | undefined => {
      const keys = key.split(".");
      let currentObj = data;

      for (const k of keys) {
        if (currentObj[k] === undefined) {
          return undefined;
        }
        currentObj = currentObj[k];
      }

      return currentObj as unknown as string;
    };

    const recursiveRender = (data: Record<string, any>, parentKey: string) => {
      for (const [key, value] of Object.entries(data)) {
        const inputKey = parentKey ? `${parentKey}.${key}` : key;

        if (typeof value === "object") {
          recursiveRender(value, inputKey);
        } else {
          const row = (
            <TableRow key={inputKey}>
              <TableCell>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger onClick={(e) => e.preventDefault()}>
                      {truncateString(inputKey)}
                    </TooltipTrigger>
                    <TooltipContent>{inputKey}</TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </TableCell>
              {editedJson.map((locale, index) => (
                <TableCell key={index}>
                  <DyTextarea
                    className={"mb-2"}
                    value={getValueFromKey(inputKey, locale.content)}
                    onChange={(e) =>
                      handleInputChange(inputKey, e.target.value, locale)
                    }
                  />
                </TableCell>
              ))}
            </TableRow>
          );

          inputRows.push(row);
        }
      }
    };

    recursiveRender(data, parentKey);

    return inputRows;
  };

  const filteredInputs = useMemo(() => {
    if (!searchQuery) return renderInputs(editedJson[0].content);

    return renderInputs(editedJson[0].content).filter((row) => {
      const key = row.key as string; // using row key as the translation key
      return key.includes(searchQuery);
    });
  }, [searchQuery, editedJson]);

  /**
   * Handle the form submission.
   * @param e - The click event.
   */
  const handleSubmit = (e: React.MouseEvent) => {
    e.preventDefault();
    onUpdate(editedJson);
  };

  return (
    <form>
      <div className="mb-4">
        <Input
          type="text"
          placeholder="Search by Translation Key"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      <Table>
        <TableHeader className={"border-b-2"}>
          <TableRow>
            <TableHead className={"py-4 w-[50px]"}>Translation Key</TableHead>
            {editedJson.map((locale, index) => (
              <TableHead className={"py-4"} key={index}>
                <LangBlock lang={locale.metadata.name} />
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>{filteredInputs}</TableBody>
      </Table>

      <div className={"sticky bottom-1 w-full flex justify-between items-center rounded backdrop-blur-sm border border-gray-200 bg-white/80 p-4"}>
        <span className={"max-w-xl hidden md:block text-xs"}>
          After clicking this button, a commit of all changes and a pull request
          will be automatically created. For the changes to take effect, you
          need to approve this pull request on GitHub
        </span>
        <Button className={""} onClick={(e) => handleSubmit(e)}>
          Save and Public
        </Button>
      </div>
    </form>
  );
};

const truncateString = (input: string, maxLength: number = 3): string => {
  const parts = input.split(".");

  if (parts.length >= maxLength)
    return parts[0] + "..." + parts[parts.length - 1];

  return input;
};

export default JsonEditorForm;
