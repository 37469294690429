import React from "react";
import { cn } from "src/lib/utils";

interface SeparatorProps extends React.HTMLAttributes<HTMLDivElement> {}

const Separator: React.FC<SeparatorProps> = ({ className, ...props }) => {
  const separatorClasses = "w-full border-gray-100 my-2 h-px";
  return <hr className={cn(className, separatorClasses)} {...props} />;
};

export default Separator;
